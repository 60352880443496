import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as ExitButtonIco } from "../../assets/ico/exitButton.svg";
import { ReactComponent as PreviewButtonIco } from "../../assets/ico/eye.svg";
import { ReactComponent as CreateButtonIco } from "../../assets/ico/plus.svg";

import styles from "./EditorSettings.module.scss";

type EditorSettingsProps = {
  onClickCreate: (id: string) => void;
  slug: string;
};

const EditorSettings: React.FC<EditorSettingsProps> = ({ onClickCreate,  slug }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  console.log(slug);

  return (
    <div className={styles.editorSettings}>
      <div className={styles.editorSettingsButton} onClick={() => navigate(-1)}>
        Выход <ExitButtonIco />
      </div>
      <div className={styles.editorSettingsButton}>
        <Link target="_blank" to={`https://qa.cyberzone.pro/preview/${slug}`}>
        Предпросмотр <PreviewButtonIco />
        </Link>
      </div>
      <div
        className={styles.editorSettingsButton}
        onClick={() => onClickCreate(id ? id : "")}
      >
        Создать на другом языке <CreateButtonIco />
      </div>
    </div>
  );
};

export default EditorSettings;
