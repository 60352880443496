import React from "react";
import qs from "qs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "./OnReview.module.scss";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { ReactComponent as ParametersIco } from "../../assets/ico/parameters.svg";
import { ReactComponent as ClearParametersico } from "../../assets/ico/clearParameters.svg";

import Input from "../../components/Input";
import NotFound from "../../components/NotFound";
import LoadingBar from "../../components/LoadingBar";
import Pagination from "../../components/Pagination";
import ArticleCard from "../../components/ArticleCard";
import DraftLoading from "../../components/DraftLoading";
import WarningModal from "../../components/WarningModal";
import FilterParameters from "../../components/FilterParameters";

import { useAppDispatch } from "../../store/store";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";
import {
  fetchAllDrafts,
  selectAllDrafts,
} from "../../store/allDrafts/allDraftsSlice";

interface QueryParams {
  articleOrder?: string;
  paginationNumber?: number;
  reviewLanguage?: string;
  searchValue?: string;
}

const OnReview: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [sortValue, setSortValue] = React.useState("NEW");
  const [reviewLanguage, setReviewLanguage] = React.useState("");
  const [paginationNumber, setPaginationNumber] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const articleStatus = "REVIEW";

  const isSearch = React.useRef(false);
  const isMounted = React.useRef(false);

  const { allDrafts, status, pagesAmounts, allDraftsSize } =
    useSelector(selectAllDrafts);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const [isLoading, setIsLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");

  const [isParameterOpen, setIsParameterOpen] = React.useState(false);
  const [isParametersActive, setIsParametersActive] = React.useState(false);

  React.useEffect(() => {
    if (
      sortValue !== "NEW" ||
      reviewLanguage.length !== 0
    ) {
      setIsParametersActive(true);
    } else {
      setIsParametersActive(false);
    }
  }, [sortValue, reviewLanguage]);

  React.useEffect(() => {
    if (status === "success") {
      if (pagesAmounts < paginationNumber + 1) {
        setPaginationNumber(0);
      }
    }
  }, [status, pagesAmounts, paginationNumber]);

  React.useEffect(() => {
    if (isMounted.current) {
      const params: QueryParams = {
      };

      if(sortValue !== "NEW"){
        params.articleOrder =sortValue
      }

      if(paginationNumber !== 0){
        params.paginationNumber = paginationNumber
      }

      if (reviewLanguage.length !== 0) {
        params.reviewLanguage = reviewLanguage;
      }

      if (searchValue.length !== 0) {
        params.searchValue = searchValue;
      }

      const queryString = qs.stringify(params);

      navigate(`?${queryString}`);
    }

    isMounted.current = true;
  }, [sortValue, reviewLanguage, paginationNumber, searchValue, navigate]);

  React.useEffect(() => {
    if (window.location.search) {
      const params = qs.parse(window.location.search.substring(1));

      if(params.articleOrder !== undefined){
        setSortValue(String(params.articleOrder));
      }

      if(params.paginationNumber !== undefined){
        setPaginationNumber(Number(params.paginationNumber));
      }

      if(params.reviewLanguage !== undefined){
        setReviewLanguage(String(params.reviewLanguage));
      }

      if(params.searchValue !== undefined){
        setSearchValue(String(params.searchValue));
      }

      setInputValue(
        typeof params.searchValue === "string" ? params.searchValue : ""
      );

      isSearch.current = true;
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);

    if (!isSearch.current) {

      dispatch(
        fetchAllDrafts({
          token,
          articleOrder: sortValue,
          articleStatus,
          articleLanguage: reviewLanguage,
          searchValue,
          pagination: paginationNumber,
        })
      );
    }

    isSearch.current = false;
  }, [
    dispatch,
    token,
    sortValue,
    reviewLanguage,
    searchValue,
    paginationNumber,
  ]);

  React.useEffect(() => {
    if (status === "error") {
      dispatch(toggleModal(true));
    }
  }, [status, dispatch]);

  const clearParameters = () => {
    setSortValue("NEW");
    setReviewLanguage("");
    setPaginationNumber(0);
    setSearchValue("");
  };

  const onClickClearParameters = () => {
    clearParameters();
    setIsParameterOpen(false)
  }

  return isLoading ? (
    <LoadingBar loading={isLoading} />
  ) : (
    <div className={styles.backgroundReviewPage}>
      <div className={styles.reviewHeader}>
        <h1> Ревью </h1>
      </div>

      {isParameterOpen && (
        <Modal
          data-bs-theme="dark"
          show={isParameterOpen}
          onHide={() => setIsParameterOpen(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Выбор Параметров</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "var(--word-bg)" }}>
            <FilterParameters
              orderValue={sortValue}
              languageValue={reviewLanguage}
              setSortValue={setSortValue}
              setArticleLanguage={setReviewLanguage}
              setPaginationNumber={setPaginationNumber}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={styles.clearParameters}
              onClick={() => onClickClearParameters()}
              variant="primary"
            >
              <ClearParametersico /> Сбросить все
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div className={styles.draftParameters}>
        <div
          onClick={() => setIsParameterOpen(true)}
          className={styles.parametersButton}
        >
          <ParametersIco /> <p>Фильтры</p>{" "}
          {isParametersActive ? (
            <div className={styles.parametersActive}></div>
          ) : (
            ""
          )}
        </div>

        <Input
          inputValue={inputValue}
          setInputValue={setInputValue}
          setSearchValue={setSearchValue}
          setPaginationNumber={setPaginationNumber}
        />
      </div>

      <div className={styles.reviewDrafts}>
        {status === "loading" ? (
          <DraftLoading length={10} />
        ) : status === "success" ? (
          allDraftsSize === 0 ? (
            <NotFound
              error={"Упс!"}
              text={"Статьи с такими фильтрами не были найдены"}
              ico={"findError"}
            />
          ) : (
            allDrafts.map((obj) => (
              <ArticleCard
                drafts={allDrafts}
                pagination={paginationNumber}
                setIsLoading={setIsLoading}
                key={obj.id}
                {...obj}
              />
            ))
          )
        ) : (
          status === "error" && <WarningModal />
        )}
      </div>

      {pagesAmounts <= 1 ? (
        " "
      ) : (
        <Pagination
          pageIndex={paginationNumber}
          pageCount={pagesAmounts}
          onChange={(index) => setPaginationNumber(index)}
        />
      )}
    </div>
  );
};

export default OnReview;
