import React, { ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import {
  DragDropContext,
  DroppableProvided,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import styles from "./DraftEditor.module.scss";

import { ReactComponent as EditorSettingsIco } from "../../assets/ico/settings.svg";
import LoadingBar from "../../components/LoadingBar";
import WarningModal from "../../components/WarningModal";

import { Input } from "@mui/material";

import { useAppDispatch } from "../../store/store";
import {
  BlockProps,
  PictureBlockProps,
  QuoteBlockProps,
} from "../../store/ownDrafts/ownDraftsSlice";
import {
  selectEditorValues,
  setEditorValue,
} from "../../store/editor/editorSlice";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import { toggleModal } from "../../store/sessionModal/sessionModalSlice";
import {
  fetchOneDraft,
  selectOneDraft,
} from "../../store/oneDraft/oneDraftSlice";
import Block from "../../components/Block";
import EditorSettings from "../../components/EditorSettings";
import DraftSettings from "../../components/DraftSettings";

const DraftEditor: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const {  slug, title, banner_url, blocks, tags, lang } =
    useSelector(selectEditorValues);

  const { oneDraft, status } = useSelector(selectOneDraft);
  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const [isLoading, setIsLoading] = React.useState(false);

  const [draftTitle, setDraftTitle] = React.useState(title);
  const [draftBannerUrl, setDraftBannerUrl] = React.useState(banner_url);
  const [blocksValues, setBlocksValues] = React.useState(blocks);
  const [draftTags, setDraftTags] = React.useState<string[]>(tags);

  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(false);

  const [langValue, setLangValue] = React.useState(lang);

  React.useEffect(() => {
    dispatch(fetchOneDraft({ token, id: id !== undefined ? id : "" }));
  }, [dispatch, token, id]);

  React.useEffect(() => {
    setIsLoading(true);
    if (status === "success" && oneDraft) {
      const currentDraft = oneDraft;

      setDraftTitle(currentDraft.title);
      setDraftBannerUrl(currentDraft.banner_url);
      if (currentDraft.blocks.length !== 0) {
        setBlocksValues(currentDraft.blocks);
      } else {
        setBlocksValues([{ type: "TEXT", data: "" }]);
      }
      setDraftTags(currentDraft.tags);
      setLangValue(currentDraft.lang);
      setIsLoading(false);
    }
  }, [status, id, oneDraft]);

  React.useEffect(() => {
    if (status === "error") {
      dispatch(toggleModal(true));
    }
  }, [status, dispatch]);

  const saveDraft = async (newBlocks?: BlockProps[], bannerUrl?: string) => {
    try {
      await axios.put(
        `/api/v1/admin/article/${id}`,
        {
          title: draftTitle,
          banner_url: bannerUrl ? bannerUrl : draftBannerUrl,
          tags: draftTags,
          blocks: newBlocks
            ? newBlocks
            : blocksValues.length === 1 &&
              (blocksValues[0].data as string).length === 0
            ? []
            : blocksValues,
          lang: langValue,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
    } catch (err) {
      console.log(err);
      toast.error(
        "Произошла ошибка при сохранении, проверьте все ли блоки заполненны"
      );
      return err;
    }
  };

  const onClickSave = async () => {
    const err = await saveDraft();
    if (!err) {
      toast.success("Сохранение прошло успешно!");
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const newItems = [...blocksValues];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);

    setBlocksValues(newItems);
  };

  if (status === "error") {
    return <WarningModal />;
  }

  if (isLoading) {
    return <LoadingBar loading={isLoading} />;
  }

  const onClickCreate = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        "/api/v1/admin/draft",
        {"article_id": `${id}`},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(
        setEditorValue({
          id: data.id,
          title: "",
          banner_url: "",
          tags: [],
          blocks: [],
          lang: null,
          slug: "",
        })
      );
      setIsSettingsOpen(false)
      setShowSettings(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      toast.error("");
    }
  };

  return (
    <div className={styles.background}>
      <div className={styles.draftHead}>
        <Input
          onChange={(e) => setDraftTitle(e.target.value)}
          value={draftTitle}
          className={styles.titleField}
          placeholder={"Заголовок..."}
        />

        <div
          className={styles.editorSettingsButton}
          onClick={() => setIsSettingsOpen(true)}
        >
          Настройки <EditorSettingsIco />
        </div>
      </div>

      {isSettingsOpen && (
        <Modal
          data-bs-theme="dark"
          show={isSettingsOpen}
          onHide={() => setIsSettingsOpen(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Настройки редактора</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "var(--word-bg)" }}>
            <EditorSettings  slug={slug} onClickCreate={onClickCreate}/>
          </Modal.Body>
        </Modal>
      )}

      {showSettings && (
        <DraftSettings
          setShowSettings={setShowSettings}
        />
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles.backgroundEditor}>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {blocksValues.map((obj, index) => (
                  <Block
                    id={id ? id : ""}
                    langValue={langValue}
                    token={token}
                    key={index}
                    index={index}
                    obj={obj}
                    draftTitle={draftTitle}
                    blocksValues={blocksValues}
                    setBlocksValues={setBlocksValues}
                    saveDraft={saveDraft}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>

      <Button
        className={styles.editButton}
        onClick={() => onClickSave()}
        variant="secondary"
      >
        Сохранить
      </Button>
    </div>
  );
};

export default DraftEditor;
