import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export const fetchArticleUsers = createAsyncThunk<
  ArtilceUserProps[],
  Record<string, string>
>("articleUsers/fetchArticleUsers", async (params) => {
    const {token} = params;
  const { data } = await axios.get<ArtilceUserProps[]>(`/api/v1/admin/article/users`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return data;
});

export type ArtilceUserProps = {
  id: string;
  nickname: string;
};

interface ArticleUsersSliceState {
  articleUsers: ArtilceUserProps[] | [];
  status: "loading" | "success" | "error";
}

const initialState: ArticleUsersSliceState = {
  articleUsers: [],
  status: "loading",
};

export const articleUsersSlice = createSlice({
  name: "articleUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArticleUsers.pending, (state) => {
      state.status = "loading";
      state.articleUsers = [];
    });
    builder.addCase(fetchArticleUsers.fulfilled, (state, action) => {
      state.status = "success";
      state.articleUsers = action.payload;
    });
    builder.addCase(fetchArticleUsers.rejected, (state, action) => {
      state.status = "error";
      state.articleUsers = [];
    });
  },
});

export const selectArticleUsers = (state: RootState) => state.articleUsersSlice;

export default articleUsersSlice.reducer;
