import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  DraftDataProps,
  DraftProps,
  ParamsProp,
} from "../ownDrafts/ownDraftsSlice";
import { RootState } from "../store";

export const fetchAllDrafts = createAsyncThunk<DraftDataProps, ParamsProp>(
  "allDrafts/fetchAllDrafts",
  async (params) => {
    const {
      token,
      articleOrder,
      articleStatus,
      articleLanguage,
      searchValue,
      articleUser,
      pagination,
    } = params;
    const { data } = await axios.get<DraftDataProps>(
      `/api/v1/admin/articles?${`limit=10&skip=${10 * pagination}`}${
        articleOrder ? `&order=${articleOrder}` : ""
      }${articleStatus ? `&article_status=${articleStatus}` : ""}${
        searchValue ? `&title=${searchValue}` : ""
      }${articleLanguage ? `&article_language=${articleLanguage}` : ""}${articleUser ? `&user_id=${articleUser}` : ""}`,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return data;
  }
);

interface AllDraftsSliceState {
  allDrafts: DraftProps[] | [];
  status: "loading" | "success" | "error";
  pagesAmounts: number;
  allDraftsSize: null | number;
}

const initialState: AllDraftsSliceState = {
  allDrafts: [],
  status: "loading",
  pagesAmounts: 1,
  allDraftsSize: null,
};

export const allDraftsSlice = createSlice({
  name: "allDrafts",
  initialState,
  reducers: {
    changeAllDraftStatus: (state, action) => {
      const { id, status } = action.payload;
      const findDraft = state.allDrafts.find((draft) => draft.id === id);

      if (findDraft) {
        findDraft.status = status;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllDrafts.pending, (state) => {
      state.status = "loading";
      state.allDrafts = [];
    });
    builder.addCase(fetchAllDrafts.fulfilled, (state, action) => {
      state.status = "success";
      state.allDrafts = action.payload.items;
      state.pagesAmounts = action.payload.amount_of_pages;
      state.allDraftsSize = action.payload.items_size;
    });
    builder.addCase(fetchAllDrafts.rejected, (state, action) => {
      state.status = "error";
      state.allDrafts = [];
    });
  },
});

export const selectAllDrafts = (state: RootState) => state.allDraftsSlice;

export const { changeAllDraftStatus } = allDraftsSlice.actions;

export default allDraftsSlice.reducer;
