import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";

export const fetchArticleLanguages = createAsyncThunk<string[],
  Record<string, string>
>("articleLanguages/fetchArticleLanguages", async (params) => {
  const { token, draftId} = params;
  const { data } = await axios.get<string[]>(
    `/api/v1/admin/article/${draftId}/available-lang`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return data;
});

interface ArticleLanguagesSliceState {
  articleLanguages: string[] | [];
  status: "loading" | "success" | "error";
}

const initialState: ArticleLanguagesSliceState = {
  articleLanguages: [],
  status: "loading",
};

export const articleLanguagesSlice = createSlice({
  name: "articleUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchArticleLanguages.pending, (state) => {
      state.status = "loading";
      state.articleLanguages = [];
    });
    builder.addCase(fetchArticleLanguages.fulfilled, (state, action) => {
      state.status = "success";
      state.articleLanguages = action.payload;
    });
    builder.addCase(fetchArticleLanguages.rejected, (state, action) => {
      state.status = "error";
      state.articleLanguages = [];
    });
  },
});

export const selectArticleLanguages = (state: RootState) => state.articleLanguagesSlice;

export default articleLanguagesSlice.reducer;
