import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from "react-redux";


import themeChange from "./theme/themeSlice";
import rolesSlice from "./roles/rolesSlice";
import usersSlice from "./users/usersSlice";
import loginSlice from './login/loginSlice';
import adminUserSlice from './adminUser/adminUserSlice';
import paginationSlice from './pagination/paginationSlice';
import sessionModalSlice from './sessionModal/sessionModalSlice';
import searchSlice from './search/searchSlice';
import ownDraftsSlice from './ownDrafts/ownDraftsSlice';
import editorSlice from './editor/editorSlice';
import existingTagsSlice from './tags/tagsSlice';
import allDraftsSlice from "./allDrafts/allDraftsSlice";
import oneDraftSlice from "./oneDraft/oneDraftSlice";
import articleUsersSlice from "./articelUsers/articelUsersSlice";
import articleLanguagesSlice from "./articleLanguages/articleLanguagesSlice";

export const store = configureStore({
  reducer: {
    themeChange,
    rolesSlice,
    usersSlice,
    loginSlice,
    adminUserSlice,
    paginationSlice,
    sessionModalSlice,
    searchSlice,
    ownDraftsSlice,
    editorSlice,
    existingTagsSlice,
    allDraftsSlice,
    oneDraftSlice,
    articleUsersSlice,
    articleLanguagesSlice
  },
})

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();