import React, { ChangeEvent } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { Input } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ClipLoader } from "react-spinners";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import styles from "./DraftSettings.module.scss";

import AddNewTag from "../AddNewTag";
import ModalWindow from "../ModalWindow";
import Tag from "../Tag";

import { ReactComponent as AddTagIco } from "../../assets/ico/addTag.svg";
import { ReactComponent as BannerFileIco } from "../../assets/ico/bannerFile.svg";
import { ReactComponent as ChangeIco } from "../../assets/ico/change.svg";

import {
  BlockProps,
  DraftProps,
  fetchOwnDrafts,
  updateDraft,
} from "../../store/ownDrafts/ownDraftsSlice";

import { useAppDispatch } from "../../store/store";
import { selectEditorValues } from "../../store/editor/editorSlice";
import { selectAdminUser } from "../../store/adminUser/adminUserSlice";
import {
  clearExistingTags,
  deleteTag,
  fetchExistingTags,
  selectExistingTags,
} from "../../store/tags/tagsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllDrafts } from "../../store/allDrafts/allDraftsSlice";
import {
  fetchArticleLanguages,
  selectArticleLanguages,
} from "../../store/articleLanguages/articleLanguagesSlice";

type DraftSettingsProps = {
  setShowSettings: React.Dispatch<React.SetStateAction<boolean>>;
  ownDrafts?: [] | DraftProps[];
  allDrafts?: [] | DraftProps[];
  sortValue?: string;
  statusValue?: string;
  draftLanguage?: string;
  paginationNumber?: number;
  searchValue?: string;
};

const DraftSettings: React.FC<DraftSettingsProps> = ({
  setShowSettings,
  ownDrafts,
  allDrafts,
  sortValue,
  statusValue,
  draftLanguage,
  paginationNumber,
  searchValue,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user: adminUser } = useSelector(selectAdminUser);
  const token = adminUser !== null ? adminUser.access_token : "";

  const path = window.location.pathname.split("/")[2];
  const { id: draftId } = useParams();

  const { id, title, banner_url, blocks, tags, lang, slug } =
    useSelector(selectEditorValues);

  const { existingTags, status: tagStatus } = useSelector(selectExistingTags);

  const [draftTitle, setDraftTitle] = React.useState(title);
  const [draftBannerUrl, setDraftBannerUrl] = React.useState(banner_url);
  const [draftTags, setDraftTags] = React.useState<string[]>(tags);
  const [tagInputValue, setTagInputValue] = React.useState("");
  const [langValue, setLangValue] = React.useState(lang);
  const [blocksValues, setBlocksValues] = React.useState(blocks);
  const [slugValue, setSlugValue] = React.useState(slug);

  const [isAlert, setIsAlert] = React.useState(false);
  const [deleteDraftAlert, setDeleteDraftAlert] = React.useState(false);

  const [deleteTagId, setDeleteTagId] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);

  const { articleLanguages, status } = useSelector(selectArticleLanguages);

  React.useEffect(() => {
    setDraftTitle(title);
    setDraftBannerUrl(banner_url);
    setDraftTags(tags);
    setTagInputValue("");
    setLangValue(lang);
    setBlocksValues(blocks);
  }, [title, banner_url, tags, lang, blocks]);

  React.useEffect(() => {
    if (draftId !== undefined && draftId.length > 0) {
      console.log(draftId)
      dispatch(fetchArticleLanguages({ token, draftId }));
    }
  }, [draftId]);

  const onClickTag = (name: string) => {
    setDraftTags((prev) => [...prev, name]);
    setTagInputValue("");
  };

  const tagDeleteAlert = (id: string) => {
    setIsAlert(true);
    setDeleteTagId(id);
  };

  const deleteExistingTag = async (id: string) => {
    try {
      console.log(id);
      const currentTag = existingTags.find((obj) => obj.id === id);
      if (!currentTag) {
        return;
      }
      setIsAlert(false);
      await axios.delete(`/api/v1/article/tag/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      dispatch(deleteTag(id));
      toast.success("Тег успешно удален");
    } catch (err) {
      toast.error("Данный тег нельзя удалить! Он уже привязан к статье");
      console.log(err);
    }
  };

  const updateTagValue = React.useCallback(
    debounce((str: string) => {
      dispatch(fetchExistingTags({ str }));
    }, 300),
    [dispatch]
  );

  const onChangeTagInput = (value: string) => {
    setTagInputValue(value);
    updateTagValue(value);
  };

  const updateTitleInput = React.useCallback(
    debounce(async (str: string) => {
      try {
        if (str.length < 3) {
          return;
        }

        const { data } = await axios.post(
          `/api/v1/admin/articles/verify-title`,
          {
            title: str,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (data.status === "TAKEN") {
          setDraftTitle("");
          toast.error("Статья с таким названием уже сущесвтует!");
        }
      } catch (err) {
        console.log(err);
        toast.error("Произошла ошибка при проверке заголовка!");
      }
    }, 500),
    [dispatch]
  );

  const onChangeTitleInput = (value: string) => {
    updateTitleInput(value);
    setDraftTitle(value);
  };

  const deleteDraftTag = (name: string) => {
    setDraftTags((prev) => prev.filter((obj) => obj !== name));
  };

  const addBannerPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files;

      if (files === null) {
        return;
      }

      const formData = new FormData();
      formData.append("file", files[0]);

      const { data } = await axios.post(
        `/api/v1/admin/draft/${id}/${langValue}/media`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );

      return data;
    } catch (err: any) {
      console.log(err);
      toast.error(
        err.response?.data?.detail || "Произошла ошибка при добавлении баннера"
      );
    }
  };

  const createDraft = async (url: string) => {
    try {
      const { data } = await axios.patch(
        `/api/v1/admin/article/${id}`,
        {
          title: draftTitle,
          banner_url: url,
          tags: draftTags,
          lang: langValue,
          blocks: blocksValues,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setSlugValue(data.slug);
    } catch (err: any) {
      console.log(err);
      toast.error(
        err.response?.data?.detail || "Произошла ошибка при сохранении"
      );
    }
  };

  const addBanner = async (event: ChangeEvent<HTMLInputElement>) => {
    if (draftTitle.length === 0 || langValue === null) {
      toast.error("Добавьте заголовок и выберите язык!");
      return;
    }
    setIsLoading(true);
    const data = await addBannerPhoto(event);
    setDraftBannerUrl(data.public_url);
    createDraft(data.public_url);
    setIsLoading(false);
  };

  const deleteBannerPhoto = async () => {
    await axios
      .delete(`/api/v1/admin/draft/${id}/media`, {
        data: {
          file_link: draftBannerUrl,
        },
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        setDraftBannerUrl("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.detail);
      });
  };

  const changeBannerPhoto = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsLoading(true);
      await deleteBannerPhoto();

      const data = await addBannerPhoto(event);
      setDraftBannerUrl(data.public_url);
      saveDraft(undefined, data.public_url);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const saveDraft = async (newBlocks?: BlockProps[], bannerUrl?: string) => {
    try {
      const { data } = await axios.put(
        `/api/v1/admin/article/${id}`,
        {
          title: draftTitle,
          banner_url: bannerUrl ? bannerUrl : draftBannerUrl,
          tags: draftTags,
          blocks: newBlocks ? newBlocks : blocksValues,
          lang: langValue,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      dispatch(
        updateDraft({
          id: id,
          title: data.title,
          banner_url: data.banner_url,
          tags: data.tags,
          slug: data.slug,
        })
      );

      dispatch(clearExistingTags());
    } catch (err) {
      console.log(err);
      toast.error("Произошла ошибка при сохранении!");
    }
  };

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    setLangValue(event.target.value);
  };

  const onClickSave = async () => {
    setIsLoading(true);
    await saveDraft();
    setIsLoading(false);
    navigate(`/adminPanel/drafts/${id}`);
    setShowSettings(false);
  };

  const onClickDelete = async () => {
    try {
      setDeleteDraftAlert(false);
      setShowSettings(false);

      await axios.delete(`/api/v1/admin/articles/draft/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (ownDrafts) {
        dispatch(
          fetchOwnDrafts({
            token,
            articleOrder: sortValue,
            articleStatus: statusValue ? statusValue : "DRAFT",
            articleLanguage: draftLanguage,
            searchValue,
            pagination:
              ownDrafts.length === 1
                ? 0
                : paginationNumber
                ? paginationNumber
                : 0,
          })
        );
      } else if (allDrafts) {
        dispatch(
          fetchAllDrafts({
            token,
            articleOrder: sortValue,
            articleStatus: statusValue ? statusValue : "DRAFT",
            articleLanguage: draftLanguage,
            searchValue,
            pagination:
              allDrafts.length === 1
                ? 0
                : paginationNumber
                ? paginationNumber
                : 0,
          })
        );
      }
      toast.success("Черновик успешно удален!");
    } catch (err) {
      console.log(err);
      toast.error("Ошибка при удалении черновика!");
      setIsAlert(false);
    }
  };

  const onClickCloseButton = () => {
    if (banner_url.length === 0) {
      setDeleteDraftAlert(true);
    } else {
      setShowSettings(false);
    }
  };

  const createNewTag = async () => {
    try {
      await axios.post(
        "/api/v1/article/tag",
        {
          name: tagInputValue,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      onClickTag(tagInputValue);
    } catch (err: any) {
      console.log(err);
      if (err) {
        toast.error("Такой тег уже существует!");
      }
    }
  };

  const onPressEnter = async (keyKode: number) => {
    if (keyKode === 13) {
      await createNewTag();
    }
  };

  return (
    <div className={styles.backgroundModal}>
      <div className={styles.settingsModal}>
        <div className={styles.settingsModalHeader}>
          <h2>Параметры статьи</h2>
          <div
            onClick={() => onClickCloseButton()}
            className={styles.closeButton}
          >
            X
          </div>
        </div>
        <div className={styles.settingsModalContent}>
          <div className={styles.draftSettings}>
            {isAlert && (
              <Modal
                data-bs-theme="light"
                show={isAlert}
                onHide={() => setIsAlert(false)}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Предупреждение</Modal.Title>
                </Modal.Header>
                <Modal.Body>Вы уверены что хотите удалить этот тег?</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => deleteExistingTag(deleteTagId)}
                  >
                    Удалить
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {deleteDraftAlert && (
              <Modal
                data-bs-theme="light"
                show={deleteDraftAlert}
                onHide={() => setDeleteDraftAlert(false)}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Предупреждение</Modal.Title>
                </Modal.Header>
                <Modal.Body>Если вы выйдете, черновик будет удален!</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => onClickDelete()}>
                    Удалить
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

            {/* <ModalWindow
              id="createTag"
              header={"Создание тега"}
              content={
                <AddNewTag token={token} deleteTagMedia={deleteTagMedia} />
              }
            /> */}

            <div className={styles.draftHead}>
              <label htmlFor="article-title">Название:</label>
              <Input
                id="article-title"
                onChange={(e) => onChangeTitleInput(e.target.value)}
                value={draftTitle}
                className={styles.titleField}
                placeholder={"Заголовок..."}
              />
              <p className={styles.slug}>{slugValue}</p>
            </div>

            <div className={styles.filterParameter}>
              {draftId ? (
                <FormControl
                  sx={{ minWidth: 150 }}
                  size="small"
                  disabled={lang !== null && true}
                >
                  <InputLabel id="draftLanguage-label">Язык статьи</InputLabel>
                  <Select
                    labelId="draftLanguage"
                    id="demo-simple-select-autowidth"
                    value={langValue || ""}
                    onChange={handleChangeLanguage}
                    autoWidth
                    label="Язык статьи"
                  >
                    <MenuItem value="">
                      <em>Выбрать</em>
                    </MenuItem>
                    {articleLanguages.map((obj) => (
                      <MenuItem key={obj} value={obj}>
                        {obj}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl
                  sx={{ minWidth: 150 }}
                  size="small"
                  disabled={lang !== null && true}
                >
                  <InputLabel id="draftLanguage-label">Язык статьи</InputLabel>
                  <Select
                    labelId="draftLanguage"
                    id="demo-simple-select-autowidth"
                    value={langValue || ""}
                    onChange={handleChangeLanguage}
                    autoWidth
                    label="Язык статьи"
                  >
                    <MenuItem value="">
                      <em>Выбрать</em>
                    </MenuItem>
                    <MenuItem value={"EN"}>EN</MenuItem>
                    <MenuItem value={"RU"}>RU</MenuItem>
                    <MenuItem value={"UA"}>UA</MenuItem>
                    <MenuItem value={"FR"}>FR</MenuItem>
                    <MenuItem value={"DE"}>DE</MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>

            <div className={styles.tagsBlock}>
              <label htmlFor="tags" className={styles.tagLabel}>
                Теги:
              </label>
              <div className={styles.allTags}>
                {existingTags &&
                  existingTags
                    .filter((obj) => !draftTags.includes(obj.name))
                    .map((existingTag) => (
                      <Tag
                        key={existingTag.id}
                        name={existingTag.name}
                        id={existingTag.id}
                        onClickFunction={onClickTag}
                        onClickDeleteFunction={tagDeleteAlert}
                      />
                    ))}
              </div>

              <div className={styles.searchTagInput}>
                <input
                  id="tags"
                  value={tagInputValue}
                  onChange={(event) => onChangeTagInput(event.target.value)}
                  onKeyDown={(event) => onPressEnter(event.keyCode)}
                />
                <div className={styles.tagLoading}>
                  {tagStatus === "loading" && <ClipLoader color="white" />}
                </div>
                <AddTagIco onClick={() => createNewTag()} />
              </div>

              <div className={styles.draftTags}>
                {draftTags.map((obj, index) => (
                  <Tag
                    key={index}
                    name={obj}
                    onClickDeleteFunction={deleteDraftTag}
                  />
                ))}
              </div>
            </div>

            <div className={styles.banner}>
              {!draftBannerUrl ? (
                <>
                  <label className={styles.bannerFile} htmlFor={"bannerPhoto"}>
                    <BannerFileIco />
                    Select a file
                  </label>
                  <input
                    onChange={(event) => addBanner(event)}
                    className={styles.bannerFileInput}
                    id={"bannerPhoto"}
                    type="file"
                  />
                </>
              ) : (
                <>
                  <label
                    htmlFor={"changePhoto"}
                    className={styles.changeBannerPhotoButton}
                  >
                    Change
                    <ChangeIco />
                  </label>
                  <img src={draftBannerUrl} alt="Баннер" />
                  <input
                    onChange={(event) => changeBannerPhoto(event)}
                    className={styles.changeFileInput}
                    id={"changePhoto"}
                    type="file"
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.settingsModalFooter}>
          <Button
            disabled={isLoading || draftBannerUrl.length === 0 ? true : false}
            className={styles.editButton}
            onClick={() => onClickSave()}
            variant="secondary"
          >
            {isLoading ? (
              <ClipLoader color="var(--secondary-bg)" />
            ) : (
              "Продолжить"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DraftSettings;
