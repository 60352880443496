import React from "react";
import debounce from "lodash.debounce";
import TextareaAutosize from "react-textarea-autosize";

import { ReactComponent as PersonIco } from "../../../assets/ico/person.svg";
import { ReactComponent as LinkIco } from "../../../assets/ico/link.svg";

import styles from "./QuoteBlock.module.scss";
import toast from "react-hot-toast";

type QuoteBlockProps = {
  index: number;
  quoteValue: string;
  authorName: string;
  linkValue: string;
  updateInputValue: (index: number, value: string, type: string) => void;
};

const QuoteBlock: React.FC<QuoteBlockProps> = ({
  index,
  quoteValue,
  authorName,
  linkValue,
  updateInputValue,
}) => {
  const [link, setLink] = React.useState(linkValue);

  const updateLinkValue = React.useCallback(
    debounce((value: string) => {
      const path = value.split("/")[0];
      if (path === "https:" || path === "http:") {
        updateInputValue(index, value, "link");
      } else {
        updateInputValue(index, "", "link");
        toast.error("URL должен начинаться с https:// или http://");
        setLink("");
      }
    }, 500),
    []
  );

  const updateInput = (value: string) => {
    setLink(value);
    updateLinkValue(value);
  };

  return (
    <div className={styles.quoteBlock}>
      <TextareaAutosize
        className={styles.quoteTextArea}
        placeholder={"Введите цитату"}
        value={quoteValue}
        onChange={(event) =>
          updateInputValue(index, event.target.value, "text")
        }
      />
      <div className={styles.sourcesBlock}>
        <div className={styles.quoteLine}>
          <PersonIco />
          <input
            placeholder="Автор"
            className={styles.quoteInput}
            value={authorName}
            onChange={(event) =>
              updateInputValue(index, event.target.value, "author_name")
            }
          />
        </div>
        <div className={styles.quoteLine}>
          <LinkIco />
          <input
            placeholder="https://"
            className={styles.quoteInput}
            value={link}
            onChange={(event) => updateInput(event.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteBlock;
