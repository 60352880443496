import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { RootState } from "../store";

export type ParamsProp = {
  token: string;
  articleOrder?: string;
  articleStatus: string;
  articleLanguage?: string;
  searchValue?: string;
  articleUser?: string;
  pagination: number;
}

export const fetchOwnDrafts = createAsyncThunk<
  DraftDataProps,
  ParamsProp
>("ownDrafts/fetchOwnDrafts", async (params) => {
  const {
    token,
    articleOrder,
    articleStatus,
    articleLanguage,
    searchValue,
    pagination,
  } = params;
  const { data } = await axios.get<DraftDataProps>(
    `/api/v1/admin/articles/own?${`limit=10&skip=${10 * pagination}`}${
      articleOrder ? `&order=${articleOrder}` : ""
    }${articleStatus ? `&article_status=${articleStatus}` : ""}${
      searchValue ? `&title=${searchValue}` : ""
    }${articleLanguage ? `&article_language=${articleLanguage}` : ""}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return data;
});

export type DraftDataProps = {
  items: DraftProps[];
  items_size: number;
  amount_of_pages: number;
};

export type DraftProps = {
  id: string;
  title: string;
  banner_url: string;
  tags: [] | string[];
  status: string;
  lang: string;
  blocks: BlockProps[];
  slug: string;
};

export type PictureBlockProps = {
  type: string;
  url: string;
  alt: string | null;
};

export type VideoBlockProps = {
  type: string;
  provider: string;
  url: string;
};

export type QuoteBlockProps = {
  text: string;
  author_name: string;
  link: string;
};
export interface BlockProps {
  type: string;
  data:
    | string
    | PictureBlockProps
    | QuoteBlockProps
    | VideoBlockProps
    | PictureBlockProps[];
}

interface DraftsSliceState {
  ownDrafts: DraftProps[] | [];
  status: "loading" | "success" | "error";
  pagesAmounts: number;
  ownDraftsSize: null | number;
}

const initialState: DraftsSliceState = {
  ownDrafts: [],
  status: "loading",
  pagesAmounts: 1,
  ownDraftsSize: null,
};
export const ownDraftsSlice = createSlice({
  name: "ownDrafts",
  initialState,
  reducers: {
    changeDraftStatus: (state, action) => {
      const { id, status } = action.payload;
      const findDraft = state.ownDrafts.find((draft) => draft.id === id);

      if (findDraft) {
        findDraft.status = status;
      }
    },
    updateDraft: (state, action) => {
      const newDrafts = state.ownDrafts.map((obj) => {
        if (obj.id === action.payload.id) {
          return {
            ...obj,
            title: action.payload.title,
            banner_url: action.payload.banner_url,
            tags: action.payload.tags,
            slug: action.payload.slug,
          };
        }
        return obj;
      });
      
      state.ownDrafts = newDrafts;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOwnDrafts.pending, (state) => {
      state.status = "loading";
      state.ownDrafts = [];
    });
    builder.addCase(fetchOwnDrafts.fulfilled, (state, action) => {
      state.status = "success";
      state.ownDrafts = action.payload.items;
      state.pagesAmounts = action.payload.amount_of_pages;
      state.ownDraftsSize = action.payload.items_size;
    });
    builder.addCase(fetchOwnDrafts.rejected, (state, action) => {
      state.status = "error";
      state.ownDrafts = [];
    });
  },
});

export const selectOwnDrafts = (state: RootState) => state.ownDraftsSlice;

export const { changeDraftStatus, updateDraft } =
  ownDraftsSlice.actions;

export default ownDraftsSlice.reducer;
